import { useCallback, useEffect } from 'react';
import axios from 'axios';

const pageSpeedInsightsController = (model, url:string) => {

  useEffect(() => {
    if(model.open && model.loadedFirstTime===false) {
      model.setOpportunities([])
      model.setLoadedFirstTime(true)
      fetchPageSpeedData(url)
    }
  }, [model.open]);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (model.loading) {
      interval = setInterval(() => {
        model.setProgress((oldProgress) => {
          if (oldProgress === 100) return 100;
          const diff = Math.random() * 5;
          return Math.min(oldProgress + diff, 100);
        });
      }, 1000);
    } else {
      clearInterval(interval!);
      model.setProgress(100);
      setTimeout(() => model.setProgress(0), 500);
    }
    return () => clearInterval(interval!);
  }, [model.loading]);

  const cleanDomain = (domain: string) => {
    let cleanedDomain = domain.replace(/^sc-domain:/i, '').trim();
    if (!/^https?:\/\//i.test(cleanedDomain)) {
      cleanedDomain = `https://${cleanedDomain}`;
    }
    return cleanedDomain;
  };

  const fetchData = async (strategy: string, currentUrl: string) => {

    const response = await axios.get(
      `https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=${encodeURIComponent(currentUrl)}&strategy=${strategy}`
    );

    let opportunities = Object.values(response.data.lighthouseResult.audits)
      .filter(audit => audit.details && audit.details.items && audit.details.type === 'opportunity') // Filter audits with 'details.items'
      .map(audit => ({
        id: audit.id,
        title: audit.title
      }));

    model.setOpportunities(opportunities)
    model.setDiagnostics(response.data.lighthouseResult.audits.diagnostics.details.items)
    model.setServerResponseTime(response.data.lighthouseResult.audits['server-response-time'].numericValue)

    return {
      fcp: response.data.lighthouseResult.audits['first-contentful-paint'].displayValue,
      lcp: response.data.lighthouseResult.audits['largest-contentful-paint'].displayValue,
      si: response.data.lighthouseResult.audits['speed-index'].displayValue,
      tbt: response.data.lighthouseResult.audits['total-blocking-time'].displayValue,
      cls: response.data.lighthouseResult.audits['cumulative-layout-shift'].displayValue,
      score: response.data.lighthouseResult.categories.performance.score * 100,
    };
  };

  const fetchPageSpeedData = useCallback(async (url) => {

    let cleanedUrl = cleanDomain(url);
    if (!cleanedUrl) {
      model.setError("El dominio no es válido. Por favor, introduce un dominio válido.");
      return;
    }
    model.setLoading(true);
    model.setError("");
    model.setData({ desktop: null, mobile: null });
    try {
      const [desktopData, mobileData] = await Promise.all([fetchData('desktop', cleanedUrl), fetchData('mobile', cleanedUrl)]);
      model.setData({ desktop: desktopData, mobile: mobileData });
    } catch (initialError) {
      const fallbackUrl = cleanedUrl.replace(/^https:\/\//, 'http://');
      try {
        const [desktopData, mobileData] = await Promise.all([fetchData('desktop', fallbackUrl), fetchData('mobile', fallbackUrl)]);
        model.setData({ desktop: desktopData, mobile: mobileData });
      } catch (fallbackError) {
        model.setError('error_get_cwv');
      }
    } finally {
      model.setLoading(false);
    }
  }, [model.url]);


  const loadData = (url) => {
    if (url) {
      model.setUrl(url)
      fetchPageSpeedData(url).catch(console.error);
    }
  }

  return {
    loadData,
    fetchPageSpeedData
  }
}

export default pageSpeedInsightsController