import { useState, useEffect } from 'react';
import { calculatePreviousDateRange, calculateListPercentageDifference, findMaxAndMinClicks } from '../utils/calculations';
import { ProjectInfo, UseProjectInfoParams } from '../types';

const useProjectInfo = ({ projectUrl, dateRange, fetchProjectInfoCallback, isNavigating }: UseProjectInfoParams) => {
  const [projectInfo, setProjectInfo] = useState<ProjectInfo | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (projectUrl && dateRange.startDate && dateRange.endDate && !isNavigating) {
      const fetchProjectInfo = async () => {
        try {
          setProjectInfo(null);
          setLoading(true);

          const currentData = await fetchProjectInfoCallback({
            project: projectUrl,
            startDate: dateRange.startDate,
            endDate: dateRange.endDate
          });

          const previousDateRange = calculatePreviousDateRange(dateRange.startDate, dateRange.endDate);
          const previousData = await fetchProjectInfoCallback({
            project: projectUrl,
            startDate: previousDateRange.startDate,
            endDate: previousDateRange.endDate
          });

          setProjectInfo({ currentData, previousData });
        } catch (error) {
          console.error('Error fetching project info:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchProjectInfo();
    }
  }, [projectUrl, dateRange, fetchProjectInfoCallback, isNavigating]);

  useEffect(() => {
    if (projectInfo?.currentData && projectInfo.previousData) {
      const currentData = projectInfo.currentData;

      const hasDifferencesCalculated = projectInfo.currentData.countries?.every(
        (item) => item.clicksDiff !== undefined
      );

      if (!hasDifferencesCalculated) {
        const previousData = projectInfo.previousData;

        const percentageDifferences = {
          dates: calculateListPercentageDifference(currentData.dates || [], previousData.dates || []),
          countries: calculateListPercentageDifference(currentData.countries || [], previousData.countries || []),
          devices: calculateListPercentageDifference(currentData.devices || [], previousData.devices || []),
          pagesList: calculateListPercentageDifference(currentData.pagesList || [], previousData.pagesList || []),
          queryList: calculateListPercentageDifference(currentData.queryList.queryList || [], previousData.queryList.queryList || []),
          lost: calculateListPercentageDifference(currentData.queryList.lost || [], previousData.queryList.lost || []),
          new: calculateListPercentageDifference(currentData.queryList.new || [], previousData.queryList.new || []),
          recuperated: calculateListPercentageDifference(currentData.queryList.recuperated || [], previousData.queryList.recuperated || []),
          searchAppearanceList: calculateListPercentageDifference(
            currentData.searchAppearanceList || [],
            previousData.searchAppearanceList || []
          ),
        };

        const maxMinClicks = {
          dates: findMaxAndMinClicks(currentData.dates),
          countries: findMaxAndMinClicks(currentData.countries),
          devices: findMaxAndMinClicks(currentData.devices),
          pagesList: findMaxAndMinClicks(currentData.pagesList),
          queryList: findMaxAndMinClicks(currentData.queryList.queryList),
          lost: findMaxAndMinClicks(currentData.queryList.lost),
          new: findMaxAndMinClicks(currentData.queryList.new),
          recuperated: findMaxAndMinClicks(currentData.queryList.recuperated),
          searchAppearanceList: findMaxAndMinClicks(currentData.searchAppearanceList),
        };

        setProjectInfo({
          currentData: {
            ...currentData,
            dates: percentageDifferences.dates,
            countries: percentageDifferences.countries,
            devices: percentageDifferences.devices,
            pagesList: percentageDifferences.pagesList,
            queryList: {
              queryList: percentageDifferences.queryList,
              lost: percentageDifferences.lost,
              new: percentageDifferences.new,
              recuperated: percentageDifferences.recuperated
            },
            searchAppearanceList: percentageDifferences.searchAppearanceList,
            maxMinClicks,
          },
          previousData,
        });
      }
    }
  }, [projectInfo]);

  return { projectInfo, loading };
};

export default useProjectInfo;