import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { extractDomain } from '../../../utils/Utils';
import { formatRows } from '../../../utils/format';

const projectDetailController = (model) => {

  const params = useParams();

  useEffect(() => {
    model.setProjectUrl(params.domain)
  }, []);


  const handleGlobalMetricToggle = (metric: string) => model.setGlobalMetrics(prev => ({
    ...prev,
    [metric]: !prev[metric]
  }));

  const filteredColumns = (columns: any[]) => columns.filter((column) => column.field === 'keys' || model.globalMetrics[column.field]);
  const formattedRows = (rows: any[], key: string) => formatRows(rows, model.projectInfo?.currentData?.maxMinClicks?.[key] || {});


  return {
    handleGlobalMetricToggle,
    filteredColumns, formattedRows
  }

}

export default projectDetailController