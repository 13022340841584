import { useState } from 'react';
import { useUserContext } from '../../../context/UserContext';
import { useMediaQuery } from '@mui/material';
import { DateRange } from '../../../components/Selectors/types';

const ProjectsPerformanceModel = () => {

  const {blur, toggleBlur} = useUserContext();
  const [columns, setColumns] = useState(2);
  const [customDomainData, setCustomDomainData] = useState<any[]>([]);
  const [dateRange, setDateRange] = useState<DateRange>({ startDate: '', endDate: '' });
  const [favoriteProjects, setFavoriteProjects] = useState<Set<string>>(new Set());
  const initialMetrics = { impressions: true, clicks: true, ctr: false, position: false };
  const [globalMetrics, setGlobalMetrics] = useState(initialMetrics);
  const [showCustomDots, setShowCustomDots] = useState<boolean>(false)
  const [showFavorites, setShowFavorites] = useState(true);
  const [showNonFavorites, setShowNonFavorites] = useState(true);

  return {
    globalMetrics, setGlobalMetrics,
    columns, setColumns,
    blur, toggleBlur,
    dateRange, setDateRange,
    customDomainData, setCustomDomainData,
    favoriteProjects, setFavoriteProjects,
    showFavorites, setShowFavorites,
    showNonFavorites, setShowNonFavorites,
    showCustomDots, setShowCustomDots
  }

}

export default ProjectsPerformanceModel