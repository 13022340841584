import { IconButton, MenuItem, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid2';
import GlobalMetricToggle from '../../../components/Charts/SearchConsoleChart/GlobalMetricToggle/GlobalMetricToggle';
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';
import ColumnSelector from '../../../components/Selectors/ColumnSelector/ColumnSelector';
import DateRangeSelector from '../../../components/Selectors/DateRangeSelector/DateRangeSelector';
import { useTranslation } from 'react-i18next';
import ToggleCustomDots from '../../../components/Charts/SearchConsoleChart/ToggleCustomDots/ToggleCustomDots';
import { extractDomain } from '../../../utils/Utils';
import Selector from '../../../components/Selector/Selector';


const ProjectsPerformanceFilterView = ({ model, controller }) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid>
        <Tooltip title={model.blur ? t('domains_hidden') : t('domains_visible')}>
          <IconButton sx={{ padding: 0 }} onClick={model.toggleBlur} aria-label="toggle blur">
            {model.blur ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </Tooltip>
      </Grid>

      <Grid sx={{ display: { xs: 'none', md: 'block' } }}>
        <ToggleCustomDots active={model.showCustomDots} onClick={() => {
          model.setShowCustomDots(!model.showCustomDots);
        }} />
      </Grid>

      <Grid sx={{ display: { xs: 'none', md: 'block' } }}>
        <GlobalMetricToggle metrics={model.globalMetrics} onToggle={controller.handleGlobalMetricToggle} />
      </Grid>

      <Grid sx={{ display: { xs: 'none', md: 'block' } }}>
        <ColumnSelector columns={model.columns} onChange={model.setColumns} />
      </Grid>

      <Grid>
        <DateRangeSelector onChange={model.setDateRange} />
      </Grid>

      {model.projectUrl &&
        <Grid>
          <Selector
            value={model.projectUrl}
            onChange={(evt) => { model.setProjectUrl(evt.target.value) }}
            blurContent={model.blur}>
            {model.dataProject.length > 0 ? (
              model.dataProject.map((project) => (
                <MenuItem key={project.id} value={project.property}>
                  <img
                    src={`https://www.google.com/s2/favicons?sz=32&domain=${extractDomain(project.property) || 'example.com'}`}
                    loading="lazy"
                    alt="favicon"
                    width={20}
                    height={20}
                    style={{ borderRadius: '50%', marginRight: '10px', verticalAlign: 'bottom' }}
                  />
                  {extractDomain(project.property)}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>{t('No projects available')}</MenuItem>
            )}
          </Selector>
        </Grid>
      }

    </Grid>
  );
};

export default ProjectsPerformanceFilterView;